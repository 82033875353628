<template>
    <div>
          <v-card class="rounded-lg mt-6 mb-2 px-6 pb-14 pt-8 card-shadow">
                <v-form ref="form" @submit.prevent="submitPaywayForm">
                    <div
                    class="heading mx-6 mb-3">
                    Enter your
                        <span
                        v-if="sessionData.paymentMethod === paymentMethods.CREDIT_CARD">
                        Card Details
                        </span>
                        <span
                        v-if="sessionData.paymentMethod === paymentMethods.DIRECT_DEBIT">
                        Direct Debit Details
                        </span>
                    </div>
                    <div id='payway-credit-card' class="mb-2"></div>
                    <div id='payway-bank-account' class="mb-2"></div>
                    <v-row
                    align="center"
                    justify="center"
                    class="mx-6">
                        <TermsAndConditions v-on:termsAndConditionChecked="checkTC" />
                    </v-row>
                    <v-row
                    align="center"
                    justify="center"
                    class="mx-6">
                        <v-btn
                        depressed
                        block
                        large
                        class="paybutton"
                        left
                        :loading="isFetching"
                        @click="submitPaywayForm"
                        :disabled="payButtonDisabled || !termsAndConditionsChecked"
                        color="primary">
                        {{sessionData.paymentType === paymentTypes.PAYMENT_ARRANGEMENT && sessionData.frequencyCode !== 1
                          ? 'Setup plan'
                          : `Pay ${formatCurrency(sessionData.paymentPlanAmount || sessionData.paymentAmount)}`}}
                        </v-btn>
                    </v-row>
                </v-form>
        </v-card>
        <v-btn
        class="mt-6 mb-2 text-none"
        text
        :color="product.ColourPrimary"
        :href="sessionData.returnURLToWebsite">
        <v-icon class="mr-1">
          mdi-arrow-left
        </v-icon>
        Return to Payment Setup
        </v-btn>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import TermsAndConditions from './TermsAndConditions.vue';
import { apiPost, formatCurrency } from '../common/utils.js';
import { paymentTypes, paymentMethods } from '../common/constants';
import { ENV } from '../common/config';
import { mapState } from 'vuex';

export default {
    name: 'Pay',
    components: {
        TermsAndConditions
    },
    setup () {
        return { v$: useVuelidate() }
    },
    mounted: function () {
        this.$nextTick(function () {
            const thisComp = this;
            if(this.sessionData.paymentMethod === paymentMethods.CREDIT_CARD){
                window.payway.createCreditCardFrame({
                    publishableApiKey: this.sessionData.publishableAPIkey,
                    tokenMode: 'callback',
                    style: this.styleCard,
                    onValid: function(){thisComp.payButtonDisabled = false;},
                    onInvalid: function(){thisComp.payButtonDisabled = true;}
                }, function(err, res){
                    thisComp.creditCardFrame = res;
                });
            }
            else {
                window.payway.createBankAccountFrame({
                    publishableApiKey: this.sessionData.publishableAPIkey,
                    tokenMode: 'callback',
                    style: this.styleAccount,
                    onValid: function(){thisComp.payButtonDisabled = false;},
                    onInvalid: function(){thisComp.payButtonDisabled = true;}
                }, function(err, res){
                    thisComp.bankAccountFrame = res;
                });
            }
        });
    },
    data() {
        return {
            isFetching: false,
            paymentTypes,
            isInvalid: false,
            payButtonDisabled: true,
            termsAndConditionsChecked: false,
            creditCardFrame: null,
            bankAccountFrame: null,
            paymentMethods,
            formatCurrency,
            styleCard : {
                'div.payway-creditcard-testfacility':{'display': 'none'},
                '#payway-creditcard-form > div > fieldset > select.payway-month': {'border-radius': '5px 0px 0px 5px'},
                '#payway-creditcard-form > div > fieldset > select.payway-year': {'border-radius': '0px 5px 5px 0px'},
                '#payway-creditcard-form > div > label.payway-creditcard-expirationseparator': {'display': 'none'},
                'div.payway-card' : { 'background-color': '#FFFFFF', 'border' : 'none'},
                '.payway-card label' : { 'color' : 'black' },
                '.payway-card label input' : { 'padding-bottom' : '10px' },
                '.payway-card legend': { 'color' : 'black' },
                '.payway-card input' : { 'color' : '#202020', 'border-radius': '5px' },
                '.payway-card select': { 'color' : '#202020' },
                '.payway-card payway-type-read-only': { 'display': 'none' },
            },
            styleAccount : {
                'div.payway-account-testfacility':{'display': 'none'},
                'div.payway-account' : { 'background-color': '#FFFFFF', 'border' : 'none'},
                '.payway-account label' : { 'color' : 'black' },
                '.payway-account legend': { 'color' : 'white' },
                '.payway-account input' : { 'color' : '#202020', 'border-radius': '5px' },
                '.payway-account select': { 'color' : '#202020' },
            }
        };
    },
    computed: mapState({
        sessionData: state => state.sessionData.sessionData,
        product: state => state.sessionData.product,
        sessionToken: state => state.sessionData.sessionToken
    }),
    methods: {
        submitPaywayForm() {
            if(this.isFetching) return;
            this.isFetching = true;
            const frame = this.creditCardFrame || this.bankAccountFrame;
            frame.getToken((err, res) => {
                if (err) {
                    this.isFetching = false;
                    this.isInvalid = true;
                    return;
                }
                apiPost(`${ENV.PAYMENT_API_URL}/paymentapi-pay`, {
                type: 'payWithSessionID',
                request: {
                    sessionID: this.sessionToken,
                    paymentData: {
                        cardType: res.creditCard ? res.creditCard.cardType : undefined,
                        singleUseTokenID: res.singleUseTokenId
                    }
                }
                }).then((response) => {
                    this.isFetching = false;
                    const isError = response.processMessages && !!response.processMessages.length;
                    if (!isError) {
                        this.$emit('payment-successfull', response);
                    } else {
                        this.$emit('payment-rejected', response);
                    }
                }).catch(() => {
                    this.isFetching = false;
                });
            });
        },
        hideInvalidCardDetailsModal() {
            this.isInvalid = false;
        },
        returnURL() {
            if (this.sessionData.returnURLToWebsite) {
                window.location.href = this.sessionData.returnURLToWebsite;
                return;
            }
            window.close();
        },
        checkTC(value){
            this.termsAndConditionsChecked = value;
        }
    }
}
</script>

<style scoped>
.heading {
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
  text-align: left;
  color: #375258;
}

button.v-btn.paybutton {
  text-transform: none !important;
  color:white !important;
  margin-top: 10px;
  font-weight: 600;
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .v-application .mx-6 {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  form.v-form {
    width: 70% !important;
        margin: 0 auto !important;
  }
  .v-application .mx-6 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}
</style>
