<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        :persistent="true"
        v-model="paymentProcessed">
        <template>
        <v-card v-if="paymentStatus === 'success'">
            <v-toolbar
            color="primary"
            dark>
                Payment success</v-toolbar>
            <v-card-text>
            <div class="pa-12">
                {{paymentType === paymentTypes.PAYMENT_ARRANGEMENT ? 'Payment arrangement setup successfully' : 'Your payment has been successfull'}}
            </div>
            </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn
                text
                @click="navigateToURLSuccess">Close</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="paymentStatus === 'fail'">
            <v-toolbar
            color="error"
            dark>
                Payment failure</v-toolbar>
            <v-card-text>
            <div class="pa-12">
                {{paymentType === paymentTypes.PAYMENT_ARRANGEMENT ? 'Payment arrangement failed' : 'Your payment has failed'}}
            </div>
            </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn
                text
                @click="hideModal">Close</v-btn>
            </v-card-actions>
        </v-card>
        </template>
    </v-dialog>
</template>

<script>
import { paymentTypes } from '../common/constants';
import { mapState } from 'vuex';

export default {
  name: 'PaymentResult',
  props: {
    paymentStatus: String,
    paymentProcessed: Boolean,
    paymentType: Number
  },
  data() {
      return {
        paymentTypes
      };
  },
  computed: mapState({
    sessionData: state => state.sessionData.sessionData,
    product: state => state.sessionData.product,
    sessionToken: state => state.sessionData.sessionToken
  }),
  methods: {
    hideModal() {
        this.$emit('hide-modal-paymentresult');
    },
    navigateToURLSuccess() {
        if(this.sessionData.returnURLSuccess){
            window.location.href = `${this.sessionData.returnURLSuccess}`;
        } else{
            window.location.href = "/paymentSuccessfull";
        }
    }
  }
}
</script>

<style scoped>

</style>
