<template>
    <div class="box-customer-summary">
        <v-card class="customer-summary no-shadow rounded-lg text-left px-6 pt-4 pb-5">
            <v-row class="mt-5">
                <v-img
                    class="ml-5"
                    v-if="!!product"
                    max-height="150"
                    max-width="60"
                    :src="product.LogoURL">
                </v-img>
            </v-row>
            <v-row>
                <v-col class="customer-details">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{customerName}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{product ? product.ProductName : ''}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col>
                    <v-list-item
                        class="total"
                        three-line
                        v-if="!!sessionData && (sessionData.type === 'paywithpayway' || sessionData.type === 'paywithcompanydirectdebit')">
                        <v-list-item-content>
                            <v-list-item-title class="text-h4">
                                {{formatAmount}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{sessionData.frequencyCode ? frequencyCodes[sessionData.frequencyCode] : 'Total'}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                                class="start-date"
                                v-if="sessionData.frequencyCode">
                                <span v-if="sessionData.frequencyCode != 1">
                                  Starting {{commencementDate}}
                                </span>
                                <span v-if="sessionData.frequencyCode == 1">
                                  Paying on {{commencementDate}}
                                </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                                class="start-date"
                                v-if="!sessionData.frequencyCode">
                                Paying Today
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import { paymentTypes, frequencyCodes } from '../common/constants.js';
import { formatCurrency } from '../common/utils.js';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'CustomerSummary',
  data() {
    return {
        paymentTypes: paymentTypes,
        frequencyCodes
    };
  },
  computed: mapState({
    sessionData: state => state.sessionData.sessionData,
    product: state => state.sessionData.product,
    customerName(state) {
        const debtor = state.sessionData.debtor;
        return `${debtor.FirstName} ${debtor.Surname}`;
    },
    formatAmount(){
        const amount = this.sessionData.paymentType === paymentTypes.PAYMENT_ARRANGEMENT ? this.sessionData.paymentPlanAmount : this.sessionData.paymentAmount;
        return formatCurrency(amount);
    },
    commencementDate(){
		const datemoment = moment(this.sessionData.commencementDate);
		return datemoment.format('DD MMM, YYYY');
    },
    todaysDate(){
		const datemoment = moment();
		return datemoment.format('DD MMM, YYYY');
    }
  }),
}
</script>
<style>
.main-div {
    margin-bottom: 100px;
}

.box-customer-summary {
    margin-right: -10px;
    margin-top: 40px;
}

.customer-summary {
    font-family: Open Sans;
    color: #375258;
    width: 250px;
    background-color: #fff;
    min-width: 280px;
}

.start-date {
    padding-top: 24px;
}

.v-card.customer-summary .v-card__text {
    font-size: 1rem;
}

.v-card.card-shadow {
    box-shadow: 0 10px 18px 3px rgba(104, 116, 125, 0.10) !important;
}

.no-shadow {
    box-shadow: none !important;
}

@media screen and (max-width: 768px) {
    .box-customer-summary {
        margin-top: 0 !important;
        margin-left: 0;
        margin-right: 0;
    }

    .customer-summary {
        margin-top: 0;
        padding: 0 !important;
        width: 100% !important;
    }

    .start-date {
        padding-top: 16px !important;
    }

    .customer-details.col {
        flex-basis: auto;
    }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    .customer-summary .row {
        width: 70% !important;
        margin: 0 auto;
    }

    .customer-summary img {
        justify-content: center;
    }

    .customer-summary .col {
        width: 100% !important;
    }
}
</style>
